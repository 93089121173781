// TODO put this in contentful
import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { Container, Row, Col, Jumbotron } from 'reactstrap';

const Legal = ({ data }) => (
  <StaticQuery
    query={graphql`
      query LegalQuery {
        contentfulLegalContent {
          footerLegalContent {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    `}
    render={data => {
      return (
        <Jumbotron fluid className="small legal">
          <Container>
            <Row>
              <Col>
                <section
                  id="legalContent"
                  dangerouslySetInnerHTML={{
                    __html:
                      data.contentfulLegalContent.footerLegalContent
                        .childMarkdownRemark.html,
                  }}
                />
              </Col>
            </Row>
          </Container>
        </Jumbotron>
      );
    }}
  />
);

export default Legal;
